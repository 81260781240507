html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App .content {
  flex: 1;
}

/* Footer Styling */
.footer {
  width: 100%;
  background-color: #052c4c; /* Dark Blue */
  color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Footer Main Content */
.footer-content {
  width: 100%;
}

.footer-rights {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

/* Footer Links */
.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Creator Section with Dark Purple */
.footer-creator {
  width: 100%;
  height: 24px; 
  text-align: right;
  font-weight: bold;
  font-size: x-small;
  padding-right: 5%; 

}

.footer-creator a {
  color: #fff;
  text-decoration: none;
}

.footer-creator a:hover {
  text-decoration: underline;
}

/* Mobile Styling */
@media (max-width: 768px) {
  .footer-creator {
    text-align: center; /* Center on mobile */
  }
}
